<template>
	<div>
		<div class="flex justify-center py-10">
			<table>
				<thead>
					<tr>
						<td class="border-2 px-4 py-2 font-bold text-center">
							#
						</td>
						<td class="border-2 px-4 py-2 font-bold text-center">
							Notificación
						</td>
						<td class="border-2 px-4 py-2 font-bold text-center">Enlace</td>
						<td class="border-2 px-4 py-2 font-bold text-center">Fecha</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(notificacion, k) in notificacionesUser" :key="k">
						<td class="border-2 px-4 py-2 text-center">
							{{ inicioData + k + 1 }}
						</td>
						<td class="border-2 px-4 py-2">
							{{ notificacion.message }}
						</td>
						<td class="border-2 px-4 py-2 text-center">
							<button
								class="text-blue-600 hover:underline"
								@click="
									siguienteRuta(notificacion.linkRouter, notificacion.idRouter)
								"
							>
								Ver
							</button>
						</td>
						<td class="border-2 px-4 py-2 text-center">
							{{ formatoFecha(notificacion.created_at) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="flex justify-center mb-6">
			<span>{{ message }}</span>
		</div>
		<div class="flex justify-between">
			<div class="pl-32 py-4">
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="inicioData > 0"
					@click="cargarDataAnterior()"
				>
					Anterior
				</button>
			</div>
			<div class="pr-40 py-4">
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="continuaDataQuery"
					@click="cargarDataSiguiente()"
				>
					Siguiente
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { formatoFecha } from "@/functions.js";
export default {
	data() {
		return {
			idCurrentUser: "",
			notificacionesUser: [],
			inicioData: 0,
			finData: 0,
			paginacion: 20,
			continuaDataQuery: false
		};
	},
	async mounted() {
		this.$store.state.isLoading = true;
		this.finData = this.paginacion;
		await this.idUsuario();
		await this.notificacionQuery();
		this.$store.state.isLoading = false;
	},
	methods: {
		formatoFecha,
		cargarDataAnterior() {
			this.inicioData -= this.paginacion;
			this.finData -= this.paginacion;
			this.notificacionesUser = [];
			this.notificacionQuery();
		},
		cargarDataSiguiente() {
			this.inicioData += this.paginacion;
			this.finData += this.paginacion;
			this.notificacionesUser = [];
			this.notificacionQuery();
		},
		siguienteRuta(newRoute, newParams) {
			this.$router.push({ name: "BlankPage" });
			setTimeout(() => {
				this.$router.push({ name: newRoute, params: { id: newParams } });
			}, 5);
		},
		async idUsuario() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/idUsuario.gql")
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.idCurrentUser = String(data.data.me.id);
				})
				.catch(() => {
					this.$store.state.isLoading = false;
					alert("Error de comunicación, por favor recargue la página.");
				});
		},
		async notificacionQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/notificacionesGeneralesQuery.gql"),
					variables: {
						user_receptor: this.idCurrentUser,
						inicio: this.inicioData,
						fin: this.finData
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					//Llenado de array de notificaciones generales
					let linkRouter = "";
					let idRouter = 0;
					this.continuaDataQuery =
						data.data.notificacionesGeneralesQuery[0].continuaData;
					for (
						let index = 0;
						index < data.data.notificacionesGeneralesQuery.length;
						index++
					) {
						//Segun tipo de notificacion se envia la ruta y el ID correspondiente de cada mensaje
						if (
							data.data.notificacionesGeneralesQuery[index].compras_id != "" &&
							data.data.notificacionesGeneralesQuery[index].compras_id != null
						) {
							linkRouter = "PedidoID";
							idRouter =
								data.data.notificacionesGeneralesQuery[index].compras_id;
						}
						if (
							data.data.notificacionesGeneralesQuery[index].cotizacion_id !=
								"" &&
							data.data.notificacionesGeneralesQuery[index].cotizacion_id !=
								null
						) {
							linkRouter = "Cotizacion";
							idRouter = parseInt(
								data.data.notificacionesGeneralesQuery[index].cotizacion_id
							);
						}
						if (
							data.data.notificacionesGeneralesQuery[index].tasa_cambio != "" &&
							data.data.notificacionesGeneralesQuery[index].tasa_cambio != null
						) {
							linkRouter = "Home";
							idRouter = 0;
						}
						//Se llena el array para su respectiva llenado en el modal
						this.notificacionesUser.push({
							message: data.data.notificacionesGeneralesQuery[index].message,
							linkRouter: linkRouter,
							idRouter: idRouter,
							created_at:
								data.data.notificacionesGeneralesQuery[index].created_at
						});
					}
					this.$store.state.isLoading = false;
				})
				.catch(() => {
					this.$store.state.isLoading = false;
					alert("Error de comunicación, por favor recargue la página.");
				});
		}
	}
};
</script>

<style></style>
